.dhun-centermodal-parent {
  min-width: 100vw;
  min-height: 100vh;
  position: fixed;
  /* bring to center */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background-color: #0c003352;
  top: 0;
  left: 0;
  height: 100vh; 
}

.dhun-centermodal-main {
  background-color: #fff;
  height: 90vh;
  border-radius: 30px ;
  box-shadow: #0c0033b8 0px 2px 8px 0px;
  width: 65vw;
  min-width: 600px;
  z-index: 200;
  padding-bottom: 3rem;
  overflow: scroll;
  
}
.dhun-centermodal-top {
  padding: 1.5rem 1rem 0rem 1rem;
  
  
}

.dhun-centermodal-footer {
  margin-top: auto;
  padding: 1rem;
  bottom: 0;
  position: absolute;
  margin-bottom: 1rem;
  box-shadow: #fffae9 0px -10px 16px 0px;
  width: 25vw;
  min-width: 300px;
}
