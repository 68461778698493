.dhun-modal-parent {
  min-width: 100vw;
  min-height: 100vh;
  position: fixed;
  z-index: 100;
  background-color: #0c003352;
  top: 0;
  left: 0;
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: auto;
}

.dhun-modal-main {
  background-color: #fff;
  min-height: 100vh;
  border-radius: 30px 0px 0px 30px;
  box-shadow: #0c0033b8 0px 2px 8px 0px;
  width: 25vw;
  min-width: 300px;
  z-index: 200;
  padding-bottom: 3rem;
}
.dhun-modal-top {
  padding: 1.5rem 1rem 0rem 1rem;
  overflow: scroll;
  -webkit-overflow-scrolling: auto;
}

.dhun-modal-footer {
  margin-top: auto;
  padding: 1rem;
  bottom: 0;
  position: absolute;
  margin-bottom: 1rem;
  box-shadow: #fffae9 0px -10px 16px 0px;
  width: 25vw;
  min-width: 300px;
}
