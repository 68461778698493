@import url("https://fonts.googleapis.com/css?family=Poppins");
@import "rsuite/dist/rsuite.css";


body {
  font-family: "Poppins", sans-serif;
}

.fs-10 {
  font-size: 10px;
}

.fs-9 {
  font-size: 9px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}
.overflow-x-scroll {
  overflow-x: scroll !important;
}

.fs-dhunprimary {
  color: #ea8824 !important;
}

.bg-dhunprimary{
  background-color: #ea8824;
}

.fs-grey {
  color: #4e4e4e !important;
}

.bg-muted {
  background-color: #f2f2f2;
}

.text-pink{
  color:#F7DEE2
}

.bg-secondary {
  background-color: #ffe2b6 !important;
}


.cursor-pointer {
  cursor: pointer;
}
.font-italic-imp {
  font-style: italic !important;
}
.text-white {
  color: white;
}

.bg-mutedRed{
  background-color: #D4475A
}

.flex-gap{
  display: flex;
  gap:5rem
}

.flexbox{
  flex: 0 0 10em;
}

.gap-4-half{
gap: 3rem
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");

.login-parent {
  height: 100vh;
  width: 100vw;
  background: rgb(118, 130, 231);
  background: linear-gradient(
    90deg,
    rgba(118, 130, 231, 1) 0%,
    rgba(72, 87, 210, 1) 50%,
    rgba(118, 130, 231, 1) 100%
  );
}
.login-card {
  width: 400px;
}

.reg-card {
  border: none !important;
  background-color: white !important;
}

.App {
  min-height: 100vh;
  min-width: 100vw;
  font-family: "Poppins", sans-serif;
}
.xcn-link-pointer {
  cursor: pointer;
}

.xcn-input {
  text-align: left;
}

/* .bg-light{

} */

.xcn-fw-100 {
  font-weight: 100 !important;
}

.xcn-fw-200 {
  font-weight: 200 !important;
}

.xcn-fw-300 {
  font-weight: 300 !important;
}

.xcn-fw-400 {
  font-weight: 400 !important;
}

.xcn-fw-500 {
  font-weight: 500 !important;
}

.xcn-fw-600 {
  font-weight: 600 !important;
}

.xcn-text-14 {
  font-size: 0.9rem !important;
}

.xcn-text-12 {
  font-size: 0.8rem !important;
}
.xcn-text-10 {
  font-size: 0.7rem !important;
}

.xcn-text-16 {
  font-size: 0.95rem !important;
}

.xcn-text-18 {
  font-size: 1.15rem !important;
}
.xcn-text-xl {
  font-size: 3rem !important;
}

.xcn-link-pointer {
  cursor: pointer;
}

.xcn-table-tr {
  height: 5rem;
}

.table > tbody > tr > td {
  vertical-align: middle;
}

.margin-left-6 {
  margin-left: 6rem !important;
}

.box-shadow {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.fw-bolder{
  font-weight: bolder;
}

.dhun-center-parent {
  min-width: 100vw;
  min-height: 100vh;
  position: fixed;
  /* bring to center */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background-color: #0c003352;
  top: 0;
  left: 0;
  height: 100vh; 
}