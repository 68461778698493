.dhun-tab {
    border-bottom: 1px solid #9A9CC1;
    padding: 0 20px 10px 20px;
    margin: 0;
    list-style: none;
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #9A9CC1;
}

.dhun-tab.active{
    border-bottom: 2px solid #A862FF;
    font-weight: 500;
    color: #A862FF;
}