.nav-link1:after {
  display: block;
  font-family: "Font Awesome 5 Free";
  font-weight: 800;
  content: "\f107";
  margin-left: auto;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.nav-link1-show:after {
  transform: rotate(-180deg);
}

.activeColor {
  color: black !important;
}

.modal-w90 {
  width: 80vw !important;
  max-width: 90vw !important;
}

.animmation {
  transition: 0.3s all ease !important;
  color: red !important;
  width: 80vw !important;
}

.my-dropdown-toggle:after {
  display: none !important;
}

.my-dropdown-toggle {
  display: flex !important;
}

.my-dropdown-togglee {
  background-color: red;
  border-radius: 0%;
  border-color: red;
}

.front {
  z-index: 1500 !important;
}

.back {
  z-index: -1000 !important;
  display: none;
}
.navigation1 {
  position: fixed !important;
  left: -400px;
  display: block !important;
  width: 350px !important;
  padding: 1rem;
  transition: 0.15s all ease !important;
  z-index: 2000 !important;
  height: 100vh !important;
}

.opennavigation {
  left: 0px !important;
}

.afteranimation {
  max-height: 150px !important;
}

.animation {
  max-height: 0px;
  overflow: hidden;
  animation-delay: 0ms;
  transition: max-height 0.3s ease-out !important;
  -moz-transition: max-height 0.3s ease-out !important;
  -webkit-transition: max-height 0.3s ease-out !important;
  -o-transition: max-height 0.3s ease-out !important;
  animation-delay: 0s;
}

.applicant {
  max-height: 0px;
  overflow: hidden;
  animation-delay: 0ms;
  transition: max-height 0.3s ease-out !important;
  -moz-transition: max-height 0.3s ease-out !important;
  -webkit-transition: max-height 0.3s ease-out !important;
  -o-transition: max-height 0.3s ease-out !important;
  animation-delay: 0s;
}

.show-applicant {
  max-height: 600px !important;
}

.mover {
  width: 50px;
  height: 50px;
  background: #f06d06;
  border-radius: 50%;
  animation: move 5s infinite ease-in-out;
}

@keyframes move {
  20%,
  100% {
    opacity: 0;
    transform: translate(200px, 0);
  }
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
}

.display {
  display: block !important;
  z-index: -3;
}
.notdisplay {
  display: none !important;
  z-index: -3;
}

.showFilters {
  height: auto;
}

.hideFilters {
  height: 0px;
  padding: 0px !important;
}

.filters {
  transition: 0.3s all ease !important;
}

.filterContent {
  display: none;
}

.dropzonee {
  width: 100%;
  height: 20%;
  border: 1px solid black;
}

.noaftericon:after {
  content: none !important;
  display: none !important;
}

.list-alert {
  position: fixed !important;
  bottom: 1.5rem !important;
  left: 50% !important;
  z-index: 1030 !important;
  min-width: 420px !important;
  margin-bottom: 0 !important;
  transform: translateX(-50%) !important;
}

.alert-dark {
  color: #fff !important;
  background-color: #12263f !important;
}

.alert-dismissible {
  padding-right: 3.90625rem !important;
}

.btn-white-20 {
  background-color: rgba(255, 255, 255, 0.2) !important;
  border-color: transparent !important;
  color: #fff !important;
}

.expanded {
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 1039;
}

.expanded-animation {
  transition: all 0.3s ease-out !important;
  -moz-transition: all 0.3s ease-out !important;
  -webkit-transition: all 0.3s ease-out !important;
  -o-transition: all 0.3s ease-out !important;
}

.btnGroup {
  border: 1px solid #ccc;
  padding: 0.375rem 1rem;
  border-radius: 4px;
  line-height: normal;
}

@keyframes increaseHeight {
  from {
    max-height: 0;
  }
  to {
    max-height: 450px;
  }
}

.ques-animation {
  /* overflow-y: hidden; */
  overflow-x: visible;
  transition: all 0.3s ease-out !important;
  -moz-transition: all 0.3s ease-out !important;
  -webkit-transition: all 0.3s ease-out !important;
  -o-transition: all 0.3s ease-out !important;
  animation-name: increaseHeight;
  animation-duration: 0.4s;
}

.react-datepicker-popper {
  z-index: 90090909 !important;
  overflow-x: visible;
  background-color: red;
}

.mytasks-animation {
  max-height: 0px;
  overflow-y: hidden !important;
  transition: all 0.5s ease-in-out !important;
  -moz-transition: all 0.5s ease-in-out !important;
  -webkit-transition: all 0.5s ease-in-out !important;
  -o-transition: all 0.5s ease-in-out !important;
}

.mytasks-show {
  max-height: 500px !important;
}

.edittask-show {
  max-height: 300px !important;
  border: 1px solid #e3ebf6 !important;
  overflow-y: visible !important;
}

.edittask {
  max-height: 0px;
  overflow-y: hidden;
  transition: all 0.2s ease-in-out !important;
  -moz-transition: all 0.2s ease-in-out !important;
  -webkit-transition: all 0.2s ease-in-out !important;
  -o-transition: all 0.2s ease-in-out !important;
  border: 0px solid #e3ebf6 !important;
}

/* .row > div {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
} */

.lightBG-control-label::before {
  background-color: #ffffff;
  border: #e3ebf6 solid 0.5px;
}

.custom-control-input:checked ~ .lightBG-control-label::before {
  color: #fff;
  border-color: #2c7be5;
  background-color: #2c7be5;
}

.arrow-animate {
  transition: 300ms linear all;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.smallSelect__control {
  height: 30px !important;
  min-height: 30px !important;
  font-size: 0.8125rem !important;
  font-weight: 400 !important;
  /* width: 130px; */
}

.smallSelect__menu {
  /* display: block; */
  /* height: 30px!important; */
  overflow: scroll;
  /* width: 130px!important; */

  /* background-color: red!important; */
}

.smallSelect__option {
  /* background-color: brown!important; */
  font-size: 0.8125rem !important;
  /* margin: 0px!important; */
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.smallSelect__dropdown-indicator {
  align-items: center !important;
  height: 30px !important;
}

.smallSelect__indicator-separator {
  display: none;
}

.smallSelect__value-container {
  position: static;
  height: 30px !important;
  /* background-color: orange!important; */
  overflow: hidden;
  width: 100px !important;
}

.smallSelect__container {
  display: "inline-block" !important;
  width: "250px" !important;
  min-height: "1px" !important;
  text-align: "left" !important;
  border: "none" !important;
  background-color: purple !important;
}

.survey-select_value-container {
  height: 50px !important;
}

.survey-select__control {
  height: 50px !important;
  /* min-height: 30px!important; */
  /* font-size: 0.9rem!important; */
  /* font-weight: 400!important; */
  /* width: 130px; */
}

.survey-select__indicator-separator {
  display: none;
}

.modal-w70 {
  width: 70vw !important;
  max-width: 70vw !important;
}

.free-bird {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.141), 0 1px 3px 0 rgba(0, 0, 0, 0.122);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);

  border: 1px solid #dadce0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  background-color: white;
  margin-left: 10px;
}

.bird-container {
  position: absolute;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  display: block;
}

.bird-item {
  padding: 10px;
}

.text-input-light {
  background-color: #f2f5f7 !important;
}

.text-input-light:hover {
  background-color: #edf2f9 !important;
}

.input-border-bottom-active {
  border-radius: 0px !important;
}

.input-border-bottom-active:hover {
  border-bottom: 0.3px solid #0000001c !important;
  border-radius: 0px !important;
}

.input-border-bottom-active:focus {
  border-bottom: 1px solid #000000 !important;
  border-radius: 0px !important;
}

.options-survey:hover #dragHandler {
  color: #95aac9 !important;
}

.card-highlight-hover:hover {
  border: 1px solid #2c7be5 !important;
}

.template-gallery:hover {
  background-color: #c1c3c7;
}

.description-class {
  height: 10px;
  padding-left: 1rem;
  font-size: 0.8125rem;
  border-bottom-color: #c1c3c7 !important;
  margin-bottom: 1rem;
  resize: none;
}

.description-class:focus {
  border-bottom-color: #39afd1 !important;
}

.input-range {
  height: 10px;
  border-radius: 0px;
  border-top: 0px;
  border-bottom: 1 px solid #000000 !important;
  border-left: 0px;
  border-right: 0px;
  padding-left: 0px;
}

.smallSelect-range__control {
  height: 30px !important;
  min-height: 30px !important;
  font-size: 0.8125rem !important;
  font-weight: 400 !important;
  width: 60px;
  text-align: center;
}

.smallSelect-range__menu {
  /* display: block; */
  /* height: 30px!important; */
  overflow: scroll;
  /* width: 130px!importan7 */

  /* background-color: red!important; */
}

.smallSelect-range__option {
  /* background-color: brown!important; */
  font-size: 0.8125rem !important;
  text-align: center;
  /* margin: 0px!important; */
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.smallSelect-range__dropdown-indicator {
  align-items: center !important;
  height: 30px !important;
}

.smallSelect-range__indicator-separator {
  display: none;
}

.smallSelect-range__value-container {
  position: static;
  height: 30px !important;
  /* background-color: orange!important; */
  overflow: hidden;
  width: 100px !important;
}

.smallSelect-range__container {
  display: "inline-block" !important;
  width: "250px" !important;
  min-height: "1px" !important;
  text-align: "left" !important;
  border: "none" !important;
  background-color: purple !important;
  text-align: center;
}

.survey-textinput {
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  border-radius: 0px !important;
  padding-left: 0px;
}

.time-picker {
  border: 0px !important;
}

.calendar-class {
  z-index: 1000 !important;
}

.leaflet-container {
  width: 100%;
  height: 100%;
  border-bottom-left-radius: calc(0.5rem - 1px);
  border-bottom-left-radius: calc(0.5rem - 1px);
}

.translucent-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  color: rgba(130, 130, 130, 0.5);
  font-size: 50px;
  text-align: center;
  line-height: 100px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
}

.avatar {
  border-radius: 1000px;
}

/* .no-button{
    border: none!important;
    background-color: transparent!important;
    padding: 0px!important;
} */

@media (min-width: 768px) {
  .card-icon-2 {
    min-height: 22rem;
  }
}

.card-img-overlay1 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
  border-radius: 10px;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:focus,
a.text-light:hover {
  color: #cbd3da !important;
}

.text-light svg.icon:not([class*="bg-"]) * {
  fill: #fff;
}

.text-primary svg.icon:not([class*="bg-"]) * {
  fill: #ea8924;
}

.text-light svg.icon:not([class*="bg-"]) * {
  fill: #fff;
}

.icon-round {
  flex-shrink: 0;
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-round > .icon {
  height: 2.25rem;
}

.icon-round.bg-primary {
  background: rgba(72, 191, 132, 0.1) !important;
}

.icon-round.bg-primary-2 {
  background: rgba(15, 82, 87, 0.1) !important;
}

.icon-round.bg-primary-3 {
  background: rgba(13, 33, 41, 0.1) !important;
}

.icon-round.bg-white {
  background: rgba(255, 255, 255, 0.1) !important;
}

.hover1:hover .hover-show {
  display: block !important;
  animation: fadein 0.3s ease-in;
}

.hover-show {
  display: none;
}

.btn-circle {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 20px;
  font-size: 0.9375em;
  border-radius: 100%;
}

.btn-circle.btn-lg,
.btn-group-lg > .btn-circle.btn {
  width: 110px;
  height: 110px;
}

.btn-circle.btn-md {
  width: 70px;
  height: 70px;
}

.btn-circle.btn-sm,
.btn-group-sm > .btn-circle.btn {
  width: 50px;
  height: 50px;
}

.btn-circle.btn-xs {
  width: 44px;
  height: 44px;
  padding: 10px;
}

.btn-circle::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: inherit;
  border-radius: 100%;
  will-change: transform;
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.2, 1, 0.2, 1);
  transition: -webkit-transform 0.6s cubic-bezier(0.2, 1, 0.2, 1);
  -o-transition: transform 0.6s cubic-bezier(0.2, 1, 0.2, 1);
  transition: transform 0.6s cubic-bezier(0.2, 1, 0.2, 1);
  transition: transform 0.6s cubic-bezier(0.2, 1, 0.2, 1),
    -webkit-transform 0.6s cubic-bezier(0.2, 1, 0.2, 1);
  z-index: -1;
}

.btn-circle:focus::before,
.btn-circle:hover::before {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.preline {
  white-space: pre-line;
}
