.root {
  margin-left: 8rem;
  margin-right: 1rem;
}

.outlet {
  margin-left: 13rem;
  z-index: 2;
  background-color: #fff;
  min-height: 100vh;
  min-width: calc(100vw - 13rem);
  border-radius: 24px;
  height: 100%;
}
