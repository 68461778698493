.dhun-search-bar{
    min-width: 33rem;
    min-height: 3.5rem;
    background-color: #f0f0f0 !important;
    border: none !important;
    font-size: 18px !important;
    color: #363736 !important;
}

.dhun-search-bar-top{
    max-width: 30rem;
    min-height: 3rem;
    font-size: 18px !important;
    color: #363736 !important;
}


.search-sidebar{
    min-width: 10rem;
    z-index: 999;
    border-right: 1px solid gray;
    margin: 0 15px;
    height: 84vh;
    max-height: 84vh;
}

.search-sidebar-item {
    margin: 10px 0;
    font-weight: bolder;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.search-sidebar-item-active{
    margin: 10px 0;
    color:orange;
    font-weight: bolder;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.search-result{
    display: inline-block;
    height: fit-content;
}
