.dhun-drawer-main {
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 11px 0px !important;
  width: 150px;
  height: 100vh;
}

.xcn-dashboard-sub-nav {
  height: 100%;
  position: fixed;
  z-index: 2;
  left: 0;
  text-align: left;
  top: 0;
  width: 14rem;
  transition: all 0.5s cubic-bezier(0.13, 0.88, 1, 1);
  padding-right: 2rem;
}

.xcn-sidebar-links {
  cursor: pointer;
  font-size: 12px;
  margin: 0rem 1rem 0rem 0.7rem;
  padding: 0.6rem 0.3rem 0.5rem 1rem;
  background-color: white;
  border-radius: 10px;
  font-weight: 700;
}

hr {
  height: 3px;
  background-color: #fff;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.xcn-sidebar-links-inactive {
  opacity: 0.9;
  cursor: pointer;
  font-size: 12px;
  margin: 0rem 1rem 0rem 0.7rem;
  padding: 0.6rem 0.3rem 0.5rem 1rem;
  border-radius: 10px;
  font-weight: 700;
  color: #fff;
}

.textColor {
  color: #9a7339;
}
.activeClassTextColor {
  color: #0C0033;
}

.sidebar-item{
  padding: 0.7em;
  font-size: 1em;
  display: block;
  color: white;
  border-radius: 5px;
  transition: background-color .15s;
  margin-top: 2px;
  margin-bottom: 2px;
}

.sidebar-item:hover{
  background-color: rgba(255, 255, 255, 0.1);
}

.sidebar-title{
  cursor: pointer;
}

.sidebar-title .toggle-btn{
  transition: transform .3s;
}

.sidebar-item.open > .sidebar-title .toggle-btn{
  transform: rotate(180deg);
}

.sidebar-item.open{
  background-color: rgba(255, 255, 255, 0.2);

}

.sidebar-item .sidebar-content{
  padding-top: .25em;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}

.sidebar-item.open > .sidebar-content{
  max-height: 400px;
}
