button {
  border: none;
  background: transparent;
 
}

.head {
  display: flex;
  gap: 1rem;
  font-size: 1rem;
  padding:1rem;
  border-radius:.5rem 0 0 0;
}
.activetab{
 color: green;
 
}