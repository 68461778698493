.dhun-loader-div {
  width: 100vw;
  height: 100vh;
  background-color: #7d7dfd42;
  position: absolute;
  z-index: 999999;
}

.dhun-loader {
  position: absolute;
  top: 20%;
  left: 50%;
}
.lottie-loader{
  height: 300px;
}
