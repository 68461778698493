.sessionBookingDetailWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.7rem;
  gap: 2rem;
  height: 10%;
  flex-shrink: 1;
  margin-top: 6rem;
}

.sessionBookingDetailContent {
  display: flex;
  gap: 19rem;
  justify-content: center;
  padding: 1rem;
  align-items: center;
  height: 10%;
}

.contentAndButtonWrapper {
  display: flex;
  margin-top: 3rem;
  gap: 4rem;
}

.selector{
    width: "100%"
}