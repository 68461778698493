.dhun-table-header {
  font-size: 12px;
}

.table-wrap {
  height: 500px;
  overflow-y: auto;
}

.text {
  display: -webkit-box;
  max-width: 298px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
