.img-small-circled{
    height: 2rem;
    width: 2rem;
    border-radius: 50px;
}

.razorpay-box{
    background-color: #f1f1f1;
    padding: 8px;
    margin-top: 23px;
    border-radius: 6px;
}
