.modal-dialog {
  margin-right: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.date {
  height: 2.2rem;
  width: 29rem;
}

.selector {
  width: "100%";
}

.deleteModal {
  width: 600px;
  margin: 0 auto;
}
